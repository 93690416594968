<template>
    <v-container>
        <div class="d-md-flex mb-8 line-bottom flex-wrap pb-4">
            <h1 class="text-h1 mb-3 mb-md-0">Catalog</h1>
            <v-breadcrumbs :items="breadcrumbs" class="ml-auto pa-0"></v-breadcrumbs>
        </div>

        <p class="d-none d-md-block text-center mt-3 mb-9">Choose a sector</p>
        <SectorList></SectorList>
    </v-container>
</template>

<script>
import SectorList from "@/components/SectorList";

export default {
    name: "Catalog",
    mounted() {
        this.$store.dispatch('setPageTitle', 'Catalog')
    },
    data: () => ({
        breadcrumbs: [
            {
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            },
            {
                text: 'Catalog',
                disabled: false,
                to: { name: 'Catalog' },
                exact: true
            }
        ]

    }),
    components: {SectorList}
}
</script>

<style scoped>

</style>
