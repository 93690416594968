<template>
    <v-list two-line>
        <v-list-item v-for="(product, index) in sortedProducts" :key="index" class="px-0" @click="$emit('selectProduct', product)">
            <v-list-item-content class="pl-3">
                <v-list-item-title>{{product.name}}
                    <v-chip
                        v-if="product.archived"
                        class="ml-1"
                        color="red lighten-2"
                        x-small
                    >Discontinued</v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{product.itemNo}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
                <v-img
                    class="mr-2"
                    :src="getProductImage(product).thumb_url"
                    max-width="36"
                    max-height="36"
                    v-if="getProductImage(product)"
                ></v-img>
            </v-list-item-avatar>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: "ProductList",
    props: {
        products: {
            type: Array
        },
        images: {
            type: Array
        },
        selectedProduct: {
            type: Object,
            required: false
        }
    },
    computed: {
        sortedProducts () {
            if (this.selectedProduct) {
                return [ this.selectedProduct ];
            }
            return this.products.slice().sort((a, b) => {
                if (b.archived && !a.archived) {
                    return -1;
                }
                if (a.archived && !b.archived) {
                    return 1;
                }
                if (a.displayOrder > b.displayOrder) {
                    return -1;
                }
                if (a.displayOrder < b.displayOrder) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
        }
    },
    methods: {
        getProductImage(product) {
            return this.images.find(image => image.product === product.slug);
        },
    },
}
</script>

<style scoped>
</style>
