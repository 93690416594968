<template>
    <v-container>
        <v-skeleton-loader v-if="!category" type="image" class="mb-4"></v-skeleton-loader>
        <div v-else class="sector-header mb-4">
            <v-img
                cover
                :max-height="300"
                :src="'/img/header-' + (sector ? sector.slug : 'default-header') + '-v202101.jpg'"
                :position="(sector && sector.slug == 'poultry') ? 'center -75px' : 'center center'"
            ></v-img>
            <div class="title">
                <h1 class="text-h1 d-flex align-center">
                    <span v-if="sector">
                        {{sector.name}}
                        <v-icon
                            color="white"
                            class="ml-2 mr-3 mt-n1"
                            large
                        >
                            fal fa-angle-right
                        </v-icon>
                    </span>
                    {{ category.name }}
                </h1>
            </div>
        </div>

        <v-breadcrumbs v-if="category" :items="breadcrumbs" class="pa-0 mb-5"></v-breadcrumbs>

        <v-row class="mb-2">
            <v-col cols="12" md="4" lg="3">
                <v-btn
                    block
                    color="accent"
                    v-if="!showCategoryFilters"
                    @click="showCategoryFilters = true"
                >
                    <v-icon
                     left
                    >
                        fal fa-plus
                    </v-icon>
                    Show filters
                </v-btn>

                <CategoryFilters
                    v-if="productSeries"
                    :product-series="productSeries"
                    :search-query.sync="searchQuery"
                    :selected-sector-slugs.sync="selectedSectorSlugs"
                    :selected-product-groups-slugs.sync="selectedProductGroupsSlugs"
                    :selected-category-slugs.sync="selectedCategorySlugs"
                    :class="{ 'd-block': showCategoryFilters, 'd-none': !showCategoryFilters }"
                ></CategoryFilters>

                <v-btn
                    block
                    color="accent"
                    v-if="showCategoryFilters && $vuetify.breakpoint.smAndDown"
                    @click="showCategoryFilters = false"
                >
                    <v-icon
                     left
                    >
                        fal fa-minus
                    </v-icon>
                    Hide filters
                </v-btn>
            </v-col>
            <v-col cols="12" md="8" lg="9" v-if="productSeries !== null">
                <v-row>
                    <v-col cols="12" md="6" v-for="series in filteredProductSeries" :key="series.slug">
                        <ProductSeriesTile :series="series" :sector="sector" :category="category"></ProductSeriesTile>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import CategoryFilters from "@/components/catalog/CategoryFilters";
import ProductSeriesTile from "@/components/catalog/ProductSeriesTile";
export default {
name: "CatalogProductOverview",
    components: {ProductSeriesTile, CategoryFilters},
    mounted() {
        this.loadCategory();
        this.loadSeries();
        this.loadFiltersFromQueryString();
        this.$store.dispatch('setPageTitle', 'Product overview')

        this.showCategoryFilters = this.$vuetify.breakpoint.smAndUp
    },
    data: () => ({
        sector: null,
        category: null,
        searchQuery: null,
        selectedSectorSlugs: [],
        selectedProductGroupsSlugs: [],
        selectedCategorySlugs: [],
        productSeries: null,
        showCategoryFilters: true
    }),
    computed: {
        filteredProductSeries () {
            let series = [];
            if (!this.searchQuery && this.selectedSectorSlugs.length === 0 && this.selectedProductGroupsSlugs.length === 0) {
                series = this.productSeries;
            } else {
                series = this.productSeries.filter(s => {
                    return (!this.searchQuery || s.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
                        && (this.selectedSectorSlugs.length === 0 || (s.sectors && s.sectors.filter(se => this.selectedSectorSlugs.includes(se)).length >= 1))
                        && (this.selectedCategorySlugs.length === 0 || (s.categories && s.categories.filter(c => this.selectedCategorySlugs.includes(c)).length >= 1))
                        && (this.selectedProductGroupsSlugs.length === 0 || (s.groups && s.groups.filter(g => this.selectedProductGroupsSlugs.includes(g)).length >= 1));
                });
            }
            series.sort((a, b) => {
                if (b.archived && !a.archived) {
                    return -1;
                }
                if (a.archived && !b.archived) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

            return series;
        },
        breadcrumbs () {
            let breadcrumbs = [{
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            }, {
                text: 'Catalog',
                disabled: false,
                to: {name: 'Catalog'},
                exact: true
            }];

            if (this.sector) {
                breadcrumbs.push({
                    text: this.sector.name,
                    disabled: false,
                    to: {name: 'CatalogSector', params: {sectorSlug: this.sector.slug}},
                    exact: true
                });
            }

            breadcrumbs.push({
                text: this.category.name
            });

            return breadcrumbs;
        }
    },
    watch: {
        $route () {
            this.loadCategory();
            this.loadSeries();
        },
        selectedSectorSlugs () {
            this.updateQueryString();
        },
        selectedProductGroupsSlugs () {
            this.updateQueryString();
        },
        selectedCategorySlugs () {
            this.updateQueryString();
        },
        searchQuery () {
            this.updateQueryString();
        }
    },
    methods: {
        loadCategory() {
            this.sector = this.$route.params.sectorSlug ? this.$store.state.catalog.sectors.find(s => s.slug === this.$route.params.sectorSlug) : null;
            if (this.sector && this.selectedSectorSlugs.length === 0) {
                this.selectedSectorSlugs = [this.sector.slug];
            }
            return this.$store.dispatch('getCategory', { slug: this.$route.params.categorySlug }).then(category => {
                this.category = category;
                if (this.category && this.selectedCategorySlugs.length === 0) {
                    this.selectedCategorySlugs = [this.category.slug];
                }
            })
        },
        loadSeries() {
            return this.$store.dispatch('getProductSeries').then(series => {
                this.productSeries = series;
            })
        },
        loadFiltersFromQueryString() {
            if (window.location.search) {
                const search = location.search.substring(1);
                const pairs = search.split('&');

                // load filters from queryString
                pairs.forEach(pair => {
                    pair = pair.split('=');
                    const key = decodeURIComponent(pair[0]);
                    const value = decodeURIComponent(pair[1]);

                    if (key === 'sectors') {
                        this.selectedSectorSlugs = value.split(',');
                    }
                    if (key === 'groups') {
                        this.selectedProductGroupsSlugs = value.split(',');
                    }
                    if (key === 'categories') {
                        this.selectedCategorySlugs = value.split(',');
                    }
                    if (key === 'q') {
                        this.searchQuery = value;
                    }
                })

            }

        },
        updateQueryString() {
            let str = [];
            if (this.selectedSectorSlugs.length > 1) {
                str.push('sectors=',this.selectedSectorSlugs.join(','));
            }
            if (this.selectedProductGroupsSlugs.length >= 1) {
                str.push('groups='+this.selectedProductGroupsSlugs.join(','));
            }
            if (this.selectedCategorySlugs.length >= 1) {
                str.push('categories='+this.selectedCategorySlugs.join(','));
            }
            if (this.searchQuery) {
                str.push('q='+encodeURIComponent(this.searchQuery));
            }

            let qs = str.length >= 1 ? '?' + str.join('&') : '';
            history.replaceState(null, null, qs);
        }
    }
}
</script>

<style scoped>

</style>
