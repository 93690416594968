<template>
    <v-card
        class="offwhite-card mb-6 spare-part-list"
        elevation="0"
        v-if="parts.length > 0"
    >
        <CoolLightBox
            :items="largeImages"
            :index="imageIndex"
            :effect="'fade'"
            @close="imageIndex = null"
            :slideshow-color-bar="this.$vuetify.theme.defaults.light.primary"
            :overlay-color="'rgba(0,0,0,.91)'"
        >
        </CoolLightBox>

        <v-card-title class="primary-title d-flex align-center">
            <v-icon
                class="mr-2 fa-fw"
                color="accent"
            >
                fal fa-clipboard-list
            </v-icon>
            <span class="py-2 py-md-0">{{ listTitle }}</span>
            <v-select
                :items="products"
                v-model="product"
                label="Select product"
                item-text="name"
                item-value="slug"
                return-object
                hide-details="auto"
                class="ml-md-auto mb-2 mb-md-0"
                dark
                dense
                outlined
                clearable
                clear-icon="fal fa-times"
            ></v-select>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="visibleParts"
            :items-per-page="100"
            :footer-props="{
                itemsPerPageOptions:[10,25,50,100,-1]
            }"
            class="elevation-1"
            :mobile-breakpoint="0"
        >
            <template v-slot:item.image="{ item }">
                <v-img
                    class="mr-2"
                    :src="item.image.thumb_url"
                    max-width="36"
                    max-height="36"
                    v-if="item.image"
                    @click="imageIndex = getImageIndex(item)"
                ></v-img>
            </template>
            <template v-slot:item.itemNo="{ item }">
                {{ item.name }}
                <v-chip
                    v-if="item.archived"
                    class="ml-1"
                    color="red lighten-2"
                    x-small
                >Discontinued</v-chip>
                <v-chip
                    v-else-if="item.expiring"
                    class="ml-1"
                    color="warning"
                    x-small
                >To be expired</v-chip>
                <br />
                <small>{{item.itemNo}}</small>
            </template>
            <template v-slot:item.price="{ item }">
                <div class="d-flex justify-end" v-if="item.price">€ {{item.price}}</div>
            </template>
            <template v-slot:item.documents="{ item }">
                <div class="d-flex justify-end">
                    <v-btn icon small color="accent" v-for="document in item.documents" :key="document.url" :href="document.url" :title="document.name" target="_blank" class="ml-1">
                        <v-icon small>{{mimeTypeToIcon(document.fileType)}}</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import mimetype2fa from "../../utils/mimeTypeToFontAwesome5";
import {mapGetters} from "vuex";

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: "SparepartList",
    props: {
        listTitle: {type:String},
        parts: {type: Array},
        products: {type: Array},
        selectedProduct: {type: Object},
    },
    data: () => ({
        imageIndex: null
    }),
    components: {
        CoolLightBox,
    },
    computed: {
        headers() {
            let headers = [
                {text: 'Description', value: 'itemNo'},
                {text: 'Image', value: 'image'},
            ];

            if (this.canUserViewPrices) {
                headers.push({text: 'Gross Price', value: 'price', align: 'end'});
            }

            headers.push({text: 'Documents', value: 'documents', align: 'end'});

            return headers;
        },
        visibleParts() {
            let parts = this.parts;
            if (this.selectedProduct) {
                parts = parts.filter(p => p.products.includes(this.selectedProduct.slug));
            }
            parts.sort((a, b) => {
                if (a.archived) {
                    return 1;
                }
                if (b.archived) {
                    return -1;
                }
                if (a.expiring) {
                    return 1;
                }
                if (b.expiring) {
                    return -1;
                }
                return 0;
            });
            return parts;
        },
        largeImages() {
            let images = []

            if(this.visibleParts) {
                this.visibleParts.forEach((part) => {
                    if (part.image) {
                        images.push({
                            itemNo: part.itemNo,
                            src: part.image.full_url,
                            title: part.image.name
                        })
                    }
                })
            }

            return images
        },
        product: {
            get () {
                return this.selectedProduct;
            },
            set (product) {
                this.selectProduct(product);
            }
        },
        ...mapGetters([
            'canUserViewPrices',
        ])
    },
    methods: {
        mimeTypeToIcon(mimeType) {
            return mimetype2fa(mimeType, { prefix: 'far fa-' });
        },
        getImageIndex(part) {
            return this.largeImages.findIndex(image => image.itemNo === part.itemNo);
        },
        selectProduct(product) {
            this.$emit('selectProduct', product);
        }
    }
}
</script>

<style scoped>

</style>
