var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parts.length > 0)?_c('v-card',{staticClass:"offwhite-card mb-6 spare-part-list",attrs:{"elevation":"0"}},[_c('CoolLightBox',{attrs:{"items":_vm.largeImages,"index":_vm.imageIndex,"effect":'fade',"slideshow-color-bar":this.$vuetify.theme.defaults.light.primary,"overlay-color":'rgba(0,0,0,.91)'},on:{"close":function($event){_vm.imageIndex = null}}}),_c('v-card-title',{staticClass:"primary-title d-flex align-center"},[_c('v-icon',{staticClass:"mr-2 fa-fw",attrs:{"color":"accent"}},[_vm._v(" fal fa-clipboard-list ")]),_c('span',{staticClass:"py-2 py-md-0"},[_vm._v(_vm._s(_vm.listTitle))]),_c('v-select',{staticClass:"ml-md-auto mb-2 mb-md-0",attrs:{"items":_vm.products,"label":"Select product","item-text":"name","item-value":"slug","return-object":"","hide-details":"auto","dark":"","dense":"","outlined":"","clearable":"","clear-icon":"fal fa-times"},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.visibleParts,"items-per-page":100,"footer-props":{
            itemsPerPageOptions:[10,25,50,100,-1]
        },"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
        var item = ref.item;
return [(item.image)?_c('v-img',{staticClass:"mr-2",attrs:{"src":item.image.thumb_url,"max-width":"36","max-height":"36"},on:{"click":function($event){_vm.imageIndex = _vm.getImageIndex(item)}}}):_vm._e()]}},{key:"item.itemNo",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.archived)?_c('v-chip',{staticClass:"ml-1",attrs:{"color":"red lighten-2","x-small":""}},[_vm._v("Discontinued")]):(item.expiring)?_c('v-chip',{staticClass:"ml-1",attrs:{"color":"warning","x-small":""}},[_vm._v("To be expired")]):_vm._e(),_c('br'),_c('small',[_vm._v(_vm._s(item.itemNo))])]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [(item.price)?_c('div',{staticClass:"d-flex justify-end"},[_vm._v("€ "+_vm._s(item.price))]):_vm._e()]}},{key:"item.documents",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},_vm._l((item.documents),function(document){return _c('v-btn',{key:document.url,staticClass:"ml-1",attrs:{"icon":"","small":"","color":"accent","href":document.url,"title":document.name,"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mimeTypeToIcon(document.fileType)))])],1)}),1)]}}],null,false,2745324873)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }