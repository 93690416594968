<template>
    <v-list-item
        :href="document.url ? document.url : null"
        :target="document.url ? '_blank' : null"
        @click="expanded = !expanded"
    >
        <v-list-item-icon>
            <v-icon>{{mimeTypeToIcon(document.fileType)}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content v-if="document.video" class="editor-row">
            <v-list-item-title :class="expanded ? 'mt-1 mb-3' : ''">{{document.name}}</v-list-item-title>
            <div v-if="expanded" v-html="document.video"></div>
        </v-list-item-content>
        <v-list-item-content v-else>
            <v-list-item-title>{{document.name}}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import mimetype2fa from '../../utils/mimeTypeToFontAwesome5';

export default {
    name: "DocumentList",
    props: {
        document: {
            type: Object,
        },
    },
    data: () => ({
        expanded: false
    }),
    methods: {
        mimeTypeToIcon(mimeType) {
            return mimetype2fa(mimeType, {prefix: 'far fa-'});
        },
    },
}
</script>

<style scoped>

</style>
