<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-row class="login-form elevation-12">
                <v-col sm="5" class="primary intro d-none d-sm-flex">
                    <h1>Password reset</h1>
                    <p class="px-2 mb-4">Set a new password for your Fancom and You account.</p>
                    <v-btn outlined color="accent" small :to="{name: 'Login'}">Back to login</v-btn>
                </v-col>
                <v-col sm="7" class="pa-0">
                    <v-form ref="form" @submit.prevent="resetPassword">
                        <v-card class="elevation-0">
                            <v-card-text>
                                <p class="mb-0 py-2 text-center">
                                    <img
                                        src="/img/fancom-logo.svg"
                                        height="67"
                                    />
                                </p>
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="password"
                                    label="New password"
                                    :rules="passwordRules"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="repeatPassword"
                                    label="Repeat password"
                                    :rules="repeatPasswordRules"
                                    required
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn large type="submit" color="primary">
                                    <v-icon
                                        small
                                        left
                                    >
                                        fal fa-sign-in
                                    </v-icon>
                                    Update password
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>

export default {
    name: "ResetPassword",
    data: () => ({
        password: '',
        repeatPassword: '',
        passwordRules: [
            v => !!v || 'Required',
            v => v.length >= 6 || 'Min. 6 characters',
        ],
        error: null,
        isSubmitted: false,
    }),
    computed: {
        repeatPasswordRules () {
            return [
                v => !!v || 'Required',
                v => v === this.password || 'Passwords do not match',
                v => v.length >= 6 || 'Min. 6 characters',
            ];
        }
    },
    mounted() {
        this.$store.dispatch('setPageTitle', 'Reset password')
        if (this.$store.state.auth.isLoggedIn) {
            this.$router.push({name: "Home"})
        }
    },
    methods: {
        resetPassword() {
            // Check if form is valid
            if (!this.isSubmitted && this.$refs.form.validate()) {
                this.isSubmitted = true;
                // Submit data to auth store
                this.$store.dispatch('resetPassword', {
                    token: this.$route.params.token,
                    password: this.password,
                }).then((success) => {
                    if (success) {
                        this.$store.dispatch('showSnackbar', {color: 'success', text: 'Password updated'});
                        return this.$router.push({name: 'Login'});
                    } else {
                        this.isSubmitted = false;
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Invalid credentials'});
                    }
                }).catch((e) => {
                    this.isSubmitted = false;
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Server error'});
                })
            }
        }
    }

}
</script>

<style scoped>

</style>
