<template>
    <v-expansion-panels>
        <v-expansion-panel
            v-for="folder in documentTree"
            :key="folder.key"
            :ref="'panel-'+folder.key"
        >
            <v-expansion-panel-header>
                {{folder.documentType}} ({{folder.documents.length + (isArchiveOpened(folder.key) && getArchivedDocumentFolder(folder.key) ? getArchivedDocumentFolder(folder.key).documents.length : 0) }})
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-list dense>
                    <v-list-item-group>
                        <v-list-item
                            v-for="document in folder.documents"
                            :key="document.url"
                            :href="document.url"
                            target="_blank"
                        >
                            <v-list-item-icon>
                                <v-icon>{{mimeTypeToIcon(document.fileType)}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{document.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                    <v-btn small text v-if="getArchivedDocumentFolder(folder.key) && isArchiveOpened(folder.key) && getArchivedDocumentFolder(folder.key).documents.length >= 10" @click="hideArchived(folder.key)">Hide archived</v-btn>
                    <v-list-item-group dense v-if="getArchivedDocumentFolder(folder.key) && isArchiveOpened(folder.key)">
                        <v-subheader>Archived</v-subheader>
                        <v-list-item
                            v-for="document in getArchivedDocumentFolder(folder.key).documents"
                            :key="document.url"
                            :href="document.url"
                            target="_blank"
                        >
                            <v-list-item-icon>
                                <v-icon>{{mimeTypeToIcon(document.fileType)}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{document.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-btn small text v-if="getArchivedDocumentFolder(folder.key) && !isArchiveOpened(folder.key)" @click="showArchived(folder.key)">Show archived</v-btn>
                <v-btn small text v-if="getArchivedDocumentFolder(folder.key) && isArchiveOpened(folder.key)" @click="hideArchived(folder.key)">Hide archived</v-btn>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import mimetype2fa from '../../utils/mimeTypeToFontAwesome5';

export default {
    name: "SoftwareList",
    props: {
        documents: {
            type: Array
        },
        selectedProduct: {
            type: Object
        },
    },
    data: () => ({
        openArchives: [],
    }),
    computed: {
        documentTree () {
            return this.createDocumentTree(false);
        },
        archivedDocumentTree () {
            return this.createDocumentTree(true);
        },
    },
    methods: {
        mimeTypeToIcon(mimeType) {
            return mimetype2fa(mimeType, { prefix: 'far fa-' });
        },
        createDocumentTree(isArchived) {
            let documents = this.documents.filter(doc => doc.isArchived === isArchived && (!this.selectedProduct || doc.products.length === 0 || doc.products.includes(this.selectedProduct.slug)));

            documents = documents.reduce((acc, doc) => {
                const documentType = doc.subGroup ? doc.subGroup : 'Other';
                // zorg dat het type in de resultset zit
                if (!acc[documentType]) {
                    acc[documentType] = {
                        key: documentType,
                        documentType: documentType,
                        documents: [],
                        showArchived: false,
                    }
                }
                // voeg het document toe aan het subGroup
                acc[documentType].documents.push(doc);

                return acc;
            }, {});

            Object.keys(documents).forEach(type => {
                documents[type].documents = documents[type].documents.filter(doc => doc.isArchived === isArchived);
                documents[type].documents = documents[type].documents.sort((a, b) => ((b.name.toLowerCase() == a.name.toLowerCase()) ? 0 : ((b.name.toLowerCase() < a.name.toLowerCase()) ? 1 : -1)));
            });

            documents = Object.values(documents).sort((a, b) => (b.documentType !== 'Other' && b.documentType.toLowerCase() < a.documentType.toLowerCase()) ? 1 : -1);

            return documents;
        },
        getArchivedDocumentFolder (key) {
            return this.archivedDocumentTree.find(folder => folder.key === key);
        },
        showArchived(subGroup) {
            this.openArchives.push(subGroup);
        },
        hideArchived(subGroup) {
            this.openArchives = this.openArchives.filter(g => g !== subGroup);
            const topOffset = this.$refs['panel-' + subGroup][0].$el.getBoundingClientRect().top;
            if (topOffset < 0) {
                window.scrollTo({top: topOffset + window.pageYOffset - 250});
            }
        },
        isArchiveOpened(subGroup) {
            return this.openArchives.includes(subGroup);
        }
    }
}
</script>

<style scoped>

</style>
