<template>
    <v-container>
        <div class="d-md-flex mb-8 line-bottom pb-4">
            <v-skeleton-loader v-if="!productSeries" type="heading"></v-skeleton-loader>
            <h1 class="text-h1 mb-3 mb-md-0" v-else>{{ productSeries.name }}</h1>
            <v-breadcrumbs v-if="productSeries" :items="breadcrumbs" class="ml-auto pa-0"></v-breadcrumbs>
        </div>

        <v-alert
            v-if="productSeries && productSeries.archived"
            color="red lighten-2"
        >This product series is discontinued.</v-alert>

        <v-row class="mb-3">
            <v-col cols="12" lg="6">
                <v-card
                    class="offwhite-card mb-6 product-list"
                    elevation="0"
                    v-if="!productSeries || productSeries.products === null || (productSeries.products && productSeries.products.length > 0)"
                >
                    <v-card-title class="primary-title d-flex align-center">
                        <v-icon
                            class="mr-2 fa-fw"
                            color="accent"
                        >
                            fal fa-monitor-heart-rate
                        </v-icon>
                        <span class="py-2 py-md-0">Products</span>
                        <v-select
                            v-if="productSeries"
                            :items="productSeries.products"
                            v-model="selectedProduct"
                            label="Select product"
                            item-text="name"
                            item-value="slug"
                            return-object
                            hide-details="auto"
                            class="ml-md-auto mb-2 mb-md-0"
                            dark
                            dense
                            outlined
                            clearable
                            clear-icon="fal fa-times"
                        ></v-select>
                    </v-card-title>
                    <v-card-text>
                        <v-skeleton-loader v-if="!productSeries" type="list"></v-skeleton-loader>
                        <ProductList v-else :products="productSeries.products" :images="productSeries.images" :selected-product="selectedProduct" @selectProduct="onSelectProduct"></ProductList>
                    </v-card-text>
                </v-card>

                <v-card
                    class="mb-6 document-list"
                    elevation="0"
                    v-if="!productSeries || productSeries.documents === null || (productSeries.products && productSeries.documents.length > 0)"
                >
                    <v-card-title class="primary-title d-flex align-center">
                        <v-icon
                            class="mr-2 fa-fw"
                            color="accent"
                        >
                            fal fa-file-alt
                        </v-icon>
                        <span class="py-2 py-md-0">Documents</span>
                        <v-select
                            v-if="productSeries"
                            :items="productSeries.products"
                            v-model="selectedProduct"
                            label="Select product"
                            item-text="name"
                            item-value="slug"
                            return-object
                            hide-details="auto"
                            class="ml-md-auto mb-2 mb-md-0"
                            dark
                            dense
                            outlined
                            clearable
                            clear-icon="fal fa-times"
                        ></v-select>
                    </v-card-title>
                    <v-skeleton-loader v-if="!productSeries || !Array.isArray(documents)" type="list"></v-skeleton-loader>
                    <DocumentList v-else :documents="documents" :selected-product="selectedProduct"></DocumentList>
                </v-card>

                <v-card
                    class="mb-6 document-list"
                    elevation="0"
                    v-if="productSeries && softwareDocuments.length > 0"
                >
                    <v-card-title class="primary-title d-flex align-center">
                        <v-icon
                            class="mr-2 fa-fw"
                            color="accent"
                        >
                            fal fa-file-alt
                        </v-icon>
                        <span class="py-2 py-md-0">Software</span>
                        <v-select
                            :items="productSeries.products"
                            v-model="selectedProduct"
                            label="Select product"
                            item-text="name"
                            item-value="slug"
                            return-object
                            hide-details="auto"
                            class="ml-md-auto mb-2 mb-md-0"
                            dark
                            dense
                            outlined
                            clearable
                            clear-icon="fal fa-times"
                        ></v-select>
                    </v-card-title>
                    <SoftwareList :documents="softwareDocuments" :selected-product="selectedProduct"></SoftwareList>
                </v-card>

                <v-skeleton-loader v-if="!productSeries || !Array.isArray(productSeries.spareParts)" type="table"/>
                <SparepartList
                    v-else
                    ref="sparepartList"
                    listTitle="Spare part list"
                    :parts="spareParts"
                    :products="productSeries.products"
                    :selected-product="selectedProduct"
                    @selectProduct="onSelectProduct"
                />

                <v-skeleton-loader v-if="!productSeries || !Array.isArray(productSeries.spareParts)" type="table"/>
                <SparepartList
                    v-else
                    ref="sparepartList"
                    listTitle="Accessoires"
                    :parts="accessoireParts"
                    :products="productSeries.products"
                    :selected-product="selectedProduct"
                    @selectProduct="onSelectProduct"
                />
            </v-col>
            <v-col cols="12" lg="6">
                <v-skeleton-loader v-if="!productSeries || !Array.isArray(productSeries.images)" type="image"></v-skeleton-loader>
                <ImageList ref="imageList" v-else :images="productSeries.images" :selected-product="selectedProduct" @selectProduct="onSelectProduct"></ImageList>

                <v-card
                    elevation="0"
                    class="mb-6"
                    v-if="!productSeries || productSeries.questions === null || (productSeries.questions && productSeries.questions.length > 0)"
                >
                    <v-card-title class="primary-title">
                        <v-icon
                            class="mr-2 fa-fw"
                            color="accent"
                        >
                            fal fa-question
                        </v-icon>
                        FAQ
                    </v-card-title>
                    <v-skeleton-loader v-if="!productSeries" type="list"></v-skeleton-loader>
                    <FaqList v-else :questions="productSeries.questions"></FaqList>
                </v-card>

            </v-col>
            <v-col cols="12">
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ProductList from "@/components/catalog/ProductList";
import FaqList from "@/components/catalog/FaqList";
import SparepartList from "@/components/catalog/SparepartList";
import DocumentList from "@/components/catalog/DocumentList";
import SoftwareList from "@/components/catalog/SoftwareList";
import ImageList from "@/components/catalog/ImageList";

export default {
    name: "CatalogProductSeries",
    components: {ImageList, DocumentList, SoftwareList, SparepartList, FaqList, ProductList},
    mounted() {
        this.loadProductSeries();
        this.loadCategory();
        this.$store.dispatch('setPageTitle', 'Product series')
    },
    data: () => ({
        productSeries: null,
        selectedProduct: null,
        sector: null,
        category: null
    }),
    computed: {
        spareParts() {
            return this.productSeries.spareParts.filter(p => p.partType === 'sparepart')
        },
        accessoireParts() {
            return this.productSeries.spareParts.filter(p => p.partType === 'accessoire')
        },
        breadcrumbs() {
            let result = [{
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            }, {
                text: 'Catalog',
                disabled: false,
                to: {name: 'Catalog'},
                exact: true
            }];

            if (this.sector) {
                result.push({
                    text: this.sector.name,
                    disabled: false,
                    to: {name: 'CatalogSector', params: {sectorSlug: this.sector.slug}},
                    exact: true
                });
            }

            if (this.category) {
                result.push({
                    text: this.category.name,
                    disabled: false,
                    to: {name: 'CatalogProductOverview', params: {sectorSlug: this.sector ? this.sector.slug : null, categorySlug: this.category.slug}},
                    exact: true
                });
            }

            result.push({
                text: this.productSeries.name
            });

            return result;
        },
        softwareDocuments() {
            if (!this.productSeries || !this.productSeries.documents) {
                return [];
            }

            return this.productSeries.documents.filter(doc => doc.type === 'software');
        },
        documents() {
            let allDocs = this.productSeries.documents

            if (this.selectedProduct) {
                allDocs = [...allDocs, ...this.selectedProduct.releaseNotes]
            } else {
                this.productSeries.products.forEach(product => {
                    allDocs = [...allDocs, ...product.releaseNotes]
                })
            }

            return allDocs
        },
    },
    watch: {
        $route() {
            this.selectedProduct = null;
            this.loadProductSeries();
            this.loadCategory();
        }
    },
    methods: {
        loadProductSeries() {
            if (this.$store.state.catalog.productSeries) {
                this.productSeries = this.$store.state.catalog.productSeries.find(s => s.slug == this.$route.params.seriesSlug);
            }
            this.$store.dispatch('getProductSeriesDetail', {slug: this.$route.params.seriesSlug})
                .then(productSeries => {
                    this.productSeries = productSeries;
                })
        },
        loadCategory() {
            if (this.$route.meta.categorySlug) {
                this.sector = this.$route.meta.sectorSlug ? this.$store.state.catalog.sectors.find(s => s.slug === this.$route.meta.sectorSlug) : null;
                this.$store.dispatch('getCategory', {slug: this.$route.meta.categorySlug}).then(category => {
                    this.category = category;
                })
            }
        },
        onSelectProduct(product) {
            this.selectedProduct = product;
        },
    }
}
</script>

<style scoped>

</style>
