<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-row class="login-form elevation-12">
                <v-col sm="5" class="primary intro">
                    <h1 class="pt-3">Forgot password</h1>
                    <p class="px-2" v-if="!isSuccess">Enter the username for your Fancom account, you will be sent an e-mail with further instructions.</p>
                    <v-btn outlined color="accent" small :to="{name: 'Login'}" class="mb-3 mt-4">Back to login</v-btn>
                </v-col>
                <v-col sm="7" class="pl-0 pr-0 bg-white">
                    <p class="mb-0 py-2 text-center">
                        <img
                            src="/img/fancom-logo.svg"
                            height="67"
                        />
                    </p>
                    <v-alert
                        icon="fal fa-info"
                        prominent
                        text
                        type="accent"
                        class="mx-3"
                        v-if="isSuccess"
                    >
                        An e-mail has been sent with further instructions.
                    </v-alert>
                    <v-form v-else ref="form" @submit.prevent="forgotPassword">
                        <v-card class="elevation-0">
                            <v-card-text>
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-user"
                                    v-model="username"
                                    label="Username"
                                    :rules="usernameRules"
                                    required
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn large type="submit" color="primary">
                                    <v-icon
                                        small
                                        left
                                    >
                                        fal fa-sign-in
                                    </v-icon>
                                    Request
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>

export default {
    name: "ForgotPassword",
    data: () => ({
        username: '',
        usernameRules: [
            v => !!v || 'Required'
        ],
        error: null,
        isSuccess: false,
        isSubmitted: false,
    }),
    mounted() {
        this.$store.dispatch('setPageTitle', 'Forgot password')
        if (this.$store.state.auth.isLoggedIn) {
            this.$router.push({name: "Home"})
        }
    },
    methods: {
        forgotPassword() {
            // Check if form is valid
            if (!this.isSubmitted && this.$refs.form.validate()) {
                this.isSubmitted = true;
                // Submit data to auth store
                this.$store.dispatch('forgotPassword', {
                    username: this.username,
                }).then((success) => {
                    if (success) {
                        this.isSuccess = true;
                    } else {
                        this.isSubmitted = false;
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Server error'});
                    }
                }).catch((e) => {
                    this.isSubmitted = false;
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Server error'});
                })
            }
        }
    }

}
</script>

<style scoped>

</style>
