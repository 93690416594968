<template>
    <v-expansion-panels>
        <v-expansion-panel
            v-for="folder in documentTree"
            :key="folder.key"
            :ref="'panel-'+folder.key"
        >
            <v-expansion-panel-header>
                {{folder.documentType}} ({{folder.documents.length + (isArchiveOpened(folder.key) && getArchivedDocumentFolder(folder.key) ? getArchivedDocumentFolder(folder.key).documents.length : 0) }})
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="getSubgroups(folder).length <= 1">
                <v-list dense>
                    <v-list-item-group>
                        <DocumentListItem v-for="document in folder.documents" :document="document" :key="document.url" />
                    </v-list-item-group>
                    <v-btn small text v-if="getArchivedDocumentFolder(folder.key) && isArchiveOpened(folder.key) && getArchivedDocumentFolder(folder.key).documents.length >= 10" @click="hideArchived(folder.key)">Hide archived</v-btn>
                    <v-list-item-group dense v-if="getArchivedDocumentFolder(folder.key) && isArchiveOpened(folder.key)">
                        <v-subheader>Archived</v-subheader>
                        <DocumentListItem v-for="document in getArchivedDocumentFolder(folder.key).documents" :document="document" :key="document.url" />
                    </v-list-item-group>
                </v-list>
                <v-btn small text v-if="getArchivedDocumentFolder(folder.key) && !isArchiveOpened(folder.key)" @click="showArchived(folder.key)">Show archived</v-btn>
                <v-btn small text v-if="getArchivedDocumentFolder(folder.key) && isArchiveOpened(folder.key)" @click="hideArchived(folder.key)">Hide archived</v-btn>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else>
                <v-expansion-panels>
                    <v-expansion-panel
                        v-for="subgroup in getSubgroups(folder)"
                        :key="subgroup.key"
                    >
                        <v-expansion-panel-header>
                            {{ subgroup.key }} ({{ subgroup.documents.length }})
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <v-list-item-group>
                                    <DocumentListItem v-for="document in subgroup.documents" :document="document" :key="document.url" />
                                </v-list-item-group>
                                <v-btn small text v-if="getSubgroups(getArchivedDocumentFolder(folder.key), subgroup.key) && isArchiveOpened(folder.key+'-'+subgroup.key) && getSubgroups(getArchivedDocumentFolder(folder.key), subgroup.key).documents.length >= 10" @click="hideArchived(folder.key+'-'+subgroup.key)">Hide archived</v-btn>
                                <v-list-item-group dense v-if="getSubgroups(getArchivedDocumentFolder(folder.key), subgroup.key) && isArchiveOpened(folder.key+'-'+subgroup.key)">
                                    <v-subheader>Archived</v-subheader>
                                    <DocumentListItem v-for="document in getSubgroups(getArchivedDocumentFolder(folder.key), subgroup.key).documents" :document="document" :key="document.url" />
                                </v-list-item-group>
                            </v-list>
                            <v-btn small text v-if="getSubgroups(getArchivedDocumentFolder(folder.key), subgroup.key) && !isArchiveOpened(folder.key+'-'+subgroup.key)" @click="showArchived(folder.key+'-'+subgroup.key)">Show archived</v-btn>
                            <v-btn small text v-if="getSubgroups(getArchivedDocumentFolder(folder.key), subgroup.key) && isArchiveOpened(folder.key+'-'+subgroup.key)" @click="hideArchived(folder.key+'-'+subgroup.key)">Hide archived</v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import mimetype2fa from '../../utils/mimeTypeToFontAwesome5';
import DocumentListItem from "./DocumentListItem";

export default {
    name: "DocumentList",
    components: {DocumentListItem},
    props: {
        documents: {
            type: Array
        },
        selectedProduct: {
            type: Object
        },
    },
    data: () => ({
        documentTypes: {
            knowledgeItems: 'Knowledge items',
            manuals: 'Manuals',
            pam: 'Product Application Manual',
            productFolders: 'Product folders',
            productInfo: 'Product info',
            safetyInstructions: 'Safety instructions',
            serviceInfo: 'Service Info',
            troubleshooter: 'Troubleshooter',
            specifications: 'Specifications',
            releaseNotes: 'Release Notes',
            photo: 'Photo\'s',
            video: 'Video\'s',
        },
        openArchives: [],
    }),
    computed: {
        documentTree () {
            return this.createDocumentTree(false);
        },
        archivedDocumentTree () {
            return this.createDocumentTree(true);
        },
    },
    methods: {
        mimeTypeToIcon(mimeType) {
            return mimetype2fa(mimeType, { prefix: 'far fa-' });
        },
        createDocumentTree(isArchived) {
            let documents = this.documents.filter(doc => doc.isArchived === isArchived && doc.type !== 'software' && (!this.selectedProduct || doc.products.length === 0 || doc.products.includes(this.selectedProduct.slug)));

            documents = documents.reduce((acc, doc) => {
                // zorg dat het type in de resultset zit
                if (!acc[doc.type]) {
                    acc[doc.type] = {
                        key: doc.type,
                        documentType: this.documentTypes[doc.type] || doc.type,
                        documents: [],
                        showArchived: false,
                    }
                }
                // voeg het document toe aan het type
                acc[doc.type].documents.push(doc);

                return acc;
            }, {});

            // Zorg dat alle keys uit het archief ook bestaan.
            if (!isArchived) {
                this.archivedDocumentTree.forEach(folder => {
                    if (!documents[folder.key]) {
                        documents[folder.key] = {
                            key: folder.key,
                            documentType: this.documentTypes[folder.key] || folder.key,
                            documents: [],
                            showArchived: false,
                        };
                    }
                });
            }

            Object.keys(documents).forEach(type => {
                documents[type].documents.sort((a, b) => ((b.name.toLowerCase() == a.name.toLowerCase()) ? 0 : ((b.name.toLowerCase() < a.name.toLowerCase()) ? 1 : -1)));
            });

            documents = Object.values(documents).sort((a, b) => (b.documentType.toLowerCase() < a.documentType.toLowerCase()) ? 1 : -1);

            return documents;
        },
        getArchivedDocumentFolder (key) {
            return this.archivedDocumentTree.find(folder => folder.key === key);
        },
        showArchived(type) {
            this.openArchives.push(type);
        },
        hideArchived(subGroup) {
            this.openArchives = this.openArchives.filter(g => g !== subGroup);
            if (this.$refs['panel-' + subGroup]) {
                const topOffset = this.$refs['panel-' + subGroup][0].$el.getBoundingClientRect().top;
                if (topOffset < 0) {
                    window.scrollTo({top: topOffset + window.pageYOffset - 250});
                }
            }
        },
        isArchiveOpened(type) {
            return this.openArchives.includes(type);
        },
        getSubgroups(folder, key) {
            if(folder && folder.documents) {
                const subgroups = folder.documents.reduce((acc, doc) => {
                    // zorg dat het type in de resultset zit
                    const subGroup = doc.subGroup || 'Other';
                    if (!acc[subGroup]) {
                        acc[subGroup] = {
                            key: subGroup,
                            documents: [],
                            showArchived: false,
                        }
                    }
                    // voeg het document toe aan het type
                    acc[subGroup].documents.push(doc);

                    return acc;
                }, {});

                if (key) {
                    return subgroups[key];
                }

                return Object.values(subgroups);
            }

            return [];
        },
    }
}
</script>

<style scoped>

</style>
