<template>
    <v-card
        :to="{name: 'CatalogProductSeries', params: { seriesSlug: series.slug }}"
        class="card-hover"
        elevation="2"
    >
        <v-card-title class="d-flex text-h6">
            {{ series.name }}
            <v-chip
                v-if="series.archived"
                class="ml-2"
                color="red lighten-2"
                outlined
                x-small
            >Discontinued</v-chip>
            <div class="ml-auto">
                <v-icon color="accent">fal fa-chevron-right</v-icon>
            </div>
        </v-card-title>
<!--        <v-card-text>-->
<!--            <p class="mb-0">Afbeelding, etc...</p>-->
<!--        </v-card-text>-->
    </v-card>
</template>

<script>
export default {
    name: "ProductSeriesTile",
    props: {
        series: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
