<template>
    <v-container>
        <v-skeleton-loader v-if="!sector" type="image" class="mb-4"></v-skeleton-loader>
        <div v-else class="sector-header mb-4">
            <v-img
                cover
                :max-height="450"
                :src="'/img/header-' + sector.slug + '-v202101.jpg'"
                :position="(sector.slug == 'poultry') ? 'center -75px' : 'center center'"
            ></v-img>
            <div class="title">
                <h1 class="text-h1">{{sector.name}}</h1>
            </div>
        </div>

        <v-breadcrumbs v-if="sector" :items="breadcrumbs" class="pa-0 mb-4 mb-md-7"></v-breadcrumbs>

        <p class="d-none d-md-block mb-7">Choose a category</p>

        <v-row v-if="categories === null">
            <v-col v-for="i in 9" :key="i">
                <v-skeleton-loader type="button"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-else class="mb-1">
            <v-col
                v-for="category in categories"
                :key="category.slug"
                cols="12"
                :md="6"
                :lg="4"
                :xl="3"
            >
                <v-card
                    :to="{name:'CatalogProductOverview', params: { sectorSlug: sector.slug, categorySlug: category.slug }}"
                    class="offwhite-card card-hover"
                    elevation="0"
                >
                    <v-card-title class="d-flex text-h6">
                        {{ category.name }}
                        <div class="ml-auto">
                            <v-icon color="accent">fal fa-chevron-right</v-icon>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "CatalogSector",
    mounted () {
        this.loadSector();
        this.$store.dispatch('setPageTitle', 'Sector ' + this.sector.name)
    },
    data: () => ({
        sector: null,
        categories: null,
    }),
    computed: {
        breadcrumbs () {
            return  [{
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            }, {
                text: 'Catalog',
                disabled: false,
                to: {name: 'Catalog'},
                exact: true
            }, {
                text: this.sector.name,
            }];
        }
    },
    watch: {
        $route () {
            this.loadSector()
        }
    },
    methods: {
        loadSector() {
            this.sector = this.$store.state.catalog.sectors.find(s => s.slug === this.$route.params.sectorSlug)
            this.$store.dispatch('getCategories').then(categories => {
                this.categories = categories.filter(c => c.sectors.includes(this.sector.slug));
            }).catch(() => {
                this.categories = [];
            })
        }
    }
}
</script>

<style scoped>

</style>
