<template>
    <v-card
        class="offwhite-card category-filters"
        elevation="0"
    >
        <v-card-text class="pa-6">
            <v-text-field v-model="query" label="Search"></v-text-field>
            <v-subheader class="pa-0"><strong>Sectors</strong></v-subheader>
            <v-checkbox
                color="accent"
                v-model="sectorSelection"
                v-for="sector in sectors"
                :key="sector.slug"
                :value="sector.slug"
                hide-details="auto"
                class="mt-0 mb-4"
            >
                <template v-slot:label>
                    {{sector.name}}
                    <span class="grey--text ml-1">({{getSectorSeriesCount(sector)}})</span>
                </template>
            </v-checkbox>
            <v-subheader class="pa-0"><strong>Product groups</strong></v-subheader>
            <v-checkbox
                color="accent"
                v-model="productGroupSelection"
                v-for="productGroup in filteredProductGroups"
                :key="productGroup.slug"
                :value="productGroup.slug"
                hide-details="auto"
                class="mt-0 mb-4"
                >
                <template v-slot:label>
                    {{productGroup.name}}
                    <span class="grey--text ml-1">({{getProductGroupCount(productGroup)}})</span>
                </template>
            </v-checkbox>
            <v-subheader class="pa-0"><strong>Categories</strong></v-subheader>
            <v-checkbox
                color="accent"
                v-model="categorySelection"
                v-for="category in filteredCategories"
                :key="category.slug"
                :value="category.slug"
                hide-details="auto"
                class="mt-0 mb-4"
            >
                <template v-slot:label>
                    {{category.name}}
                    <span class="grey--text ml-1">({{getCategoryCount(category)}})</span>
                </template>
            </v-checkbox>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "CategoryFilters",
    mounted() {
        this.loadProductGroups();
        this.loadCategories();
    },
    props: {
        selectedSectorSlugs: {
            type: Array
        },
        selectedProductGroupsSlugs: {
            type: Array
        },
        selectedCategorySlugs: {
            type: Array
        },
        searchQuery: {
            type: String
        },
        productSeries: {
            type: Array
        }
    },
    data: () => ({
        productGroups: null,
        categories: null,
    }),
    computed: {
        sectorSelection: {
            get () { return this.selectedSectorSlugs; },
            set (val) {
                this.$emit('update:selectedSectorSlugs', val);
            }
        },
        productGroupSelection: {
            get () { return this.selectedProductGroupsSlugs; },
            set (val) {
                this.$emit('update:selectedProductGroupsSlugs', val);
            }
        },
        categorySelection: {
            get () { return this.selectedCategorySlugs; },
            set (val) {
                this.$emit('update:selectedCategorySlugs', val);
            }
        },
        query: {
            get () { return this.searchQuery; },
            set (val) {
                this.$emit('update:searchQuery', val);
            }
        },
        filteredProductGroups () {
            if (!this.productGroups) {
                return null;
            }

            return this.productGroups.filter(pg => this.getProductGroupCount(pg) > 0);
        },
        filteredCategories () {
            if (!this.categories) {
                return null;
            }

            return this.categories.filter(c => this.getCategoryCount(c) > 0);
        },
        ...mapState({
            sectors: state => state.catalog.sectors,
        })
    },
    methods: {
        loadProductGroups() {
            this.$store.dispatch('getProductGroups').then(groups => {
                this.productGroups = groups
            });
        },
        loadCategories() {
            this.$store.dispatch('getCategories').then(categories => {
                this.categories = categories
            });
        },
        getSectorSeriesCount(sector) {
            return this.productSeries.filter(s => s.sectors.includes(sector.slug)).length;
        },
        getProductGroupCount(productGroup) {
            return this.productSeries
                .filter(
                    s =>
                        (this.selectedSectorSlugs.length === 0 || s.sectors.filter(se => this.selectedSectorSlugs.includes(se)).length >= 1)
                        && (this.selectedCategorySlugs.length === 0 || s.categories.filter(c => this.selectedCategorySlugs.includes(c)).length >= 1)
                        && s.groups.includes(productGroup.slug)).length;
        },
        getCategoryCount(category) {
            return this.productSeries
                .filter(
                    s =>
                        (this.selectedSectorSlugs.length === 0 || s.sectors.filter(se => this.selectedSectorSlugs.includes(se)).length >= 1)
                        && (this.selectedProductGroupsSlugs.length === 0 || s.groups.filter(p => this.selectedProductGroupsSlugs.includes(p)).length >= 1)
                        && s.categories.includes(category.slug)).length;
        }
    }
}
</script>

<style scoped>

</style>
