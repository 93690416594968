<template>
    <v-container>
        <v-row class="justify-center">
            <v-col sm="5" class="pa-0">
                <h1 class="text-h1 mt-3 mb-7 text-center">Change password</h1>
                <v-card
                    elevation="2"
                >
                    <v-form ref="form" @submit.prevent="changePassword">
                        <v-card
                            class="offwhite-card"
                            elevation="0"
                        >
                            <v-card-text>
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="oldPassword"
                                    label="Current password"
                                    :rules="oldPasswordRules"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="password"
                                    label="New password"
                                    :rules="passwordRules"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="repeatPassword"
                                    label="Repeat password"
                                    :rules="repeatPasswordRules"
                                    required
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-3">
                                <v-btn color="accent" type="submit" elevation="0">
                                    <v-icon small left>fal fa-sign-in</v-icon>
                                    Change password
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ChangePassword",
    data: () => ({
        oldPassword: '',
        password: '',
        repeatPassword: '',
        oldPasswordRules: [
            v => !!v || 'Required',
        ],
        passwordRules: [
            v => !!v || 'Required',
            v => v.length >= 6 || 'Minimum of 6 characters',
        ],
        error: null,
        isSubmitted: false,
    }),
    mounted() {
        this.$store.dispatch('setPageTitle', 'Change password')
    },
    computed: {
        repeatPasswordRules () {
            return [
                v => !!v || 'Required',
                v => v === this.password || 'Passwords do not match',
                v => v.length >= 6 || 'Minimum of 6 characters',
            ];
        }
    },
    methods: {
        changePassword() {
            // Check if form is valid
            if (!this.isSubmitted && this.$refs.form.validate()) {
                this.isSubmitted = true;
                // Submit data to auth store
                this.$store.dispatch('updatePassword', {
                    old_password: this.oldPassword,
                    password: this.password,
                }).then((success) => {
                    if (success) {
                        this.$store.dispatch('showSnackbar', {color: 'success', text: 'Password changed'});
                        this.$store.dispatch('logout').then(() => {
                            this.$router.push({ name: 'Login' });
                        })
                    } else {
                        this.isSubmitted = false;
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Invalid current or new password'});
                    }
                }).catch((e) => {
                    this.isSubmitted = false;
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Invalid current or new password'});
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
