<template>
    <div class="image-gallery">
        <CoolLightBox
            :items="largeImages"
            :index="index"
            :effect="'fade'"
            @close="index = null"
            :slideshow-color-bar="this.$vuetify.theme.defaults.light.primary"
            :overlay-color="'rgba(0,0,0,.91)'"
        >
        </CoolLightBox>
        <!--            :overlay-color="'rgba(3,30,25,.95)'"-->

        <v-row class="mb-4" v-if="images.length">
            <v-col
                v-for="(image, imageIndex) in thumbImages"
                :key="imageIndex"
                class="d-flex child-flex"
                cols="3"
            >
                <!-- :class="{'is-selected': selectedProduct && (image.product === selectedProduct.slug)}" -->
                <div class="image-link" @click="index = imageIndex"
                >
                    <v-img
                        :src="image.thumb_url"
                        aspect-ratio="1"
                    >
                    </v-img>
                    <v-icon
                    >
                        fal fa-search-plus
                    </v-icon>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: "ImageList",
    props: {
        images: {
            type: Array
        },
        selectedProduct: {
            type: Object
        },
    },
    data: () => ({
        index: null,
    }),
    components: {
        CoolLightBox,
    },
    computed: {
        thumbImages() {
            return this.images.filter(image => !this.selectedProduct || this.selectedProduct.slug === image.product);
        },
        largeImages() {
            let images = []

            this.images.forEach((image) => {
                if (!this.selectedProduct || this.selectedProduct.slug === image.product) {
                    images.push({
                        src: image.full_url,
                        title: image.name
                    })
                }
            })

            return images
        },
    },
    methods: {
        openProduct(product) {
            this.index = this.images.findIndex(image => image.product === product.slug);
        },
        selectProduct(product) {
            this.$emit('selectProduct', product);
        }
    }
}
</script>

<style scoped>
.image-link {
    border: 2px solid white;
}

.image-link.is-selected {
    border-color: #004237
}
</style>
